<template>
  <div class="text-center">
    <Loader :loading="loading" animation="calendar" />
    <h1 class="title">Planificador</h1>
    <div class="mx-12 my-6">
      <v-stepper v-model="e1" class="animate__animated animate__bounceInLeft my-0">
        <v-stepper-header>
          <template v-for="(step, n) in steps">
            <v-stepper-step :complete="e1 > n + 1" :step="n + 1" v-bind:key="n" edit-icon="$complete">{{ e1 == n + 1 ? "Paso " + (n + 1) : step }}</v-stepper-step>
            <v-divider v-if="n + 1 !== steps.length" v-bind:key="step"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
      <div class="mt-4">
        <div v-if="e1 == 1" class="animate__animated animate__bounceInRight">
          <v-row no-gutters class="itemsCenter">
            <v-card width="500">
              <v-toolbar dense dark color="primary" elevation="2">
                <v-toolbar-title>Seleccionar el alcance de los empleados</v-toolbar-title>
              </v-toolbar>
              <v-row class="px-4 itemsCenter">
                <div class="pa-4">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip @click="changeSelect('users')"> Por empleados </v-chip>
                    <v-chip @click="changeSelect('department')"> Por departamentos </v-chip>
                  </v-chip-group>
                </div>
              </v-row>
            </v-card>
          </v-row>
          <br />
          <br />
          <Select-Choice :selectArray="select" :noSelectArray="noSelect" :key="noSelect.length" :select.sync="select"></Select-Choice>
        </div>
        <div v-if="e1 == 2" class="animate__animated animate__bounceInRight">
          <h2 class="subtitle">Seleccione días consecutivos</h2>
          <br />
          <br />
          <v-card>
            <v-row class="pa-4">
              <v-col cols="5">
                <Date-Picker v-model="forms.search.fecha_inicio" label="Fecha inicio" offYear hideDetails offLimit/>
              </v-col>
              <v-col cols="5">
                <Date-Picker v-model="forms.search.fecha_fin" label="Fecha fin" offYear hideDetails offLimit/>
              </v-col>
              <v-col cols="2">
                <Basic-Btn text="Buscar" color="primary" icon="mdi-magnify" @click="avaibleChips = (forms.search.fecha_inicio && forms.search.fecha_fin)" :disabled="!(forms.search.fecha_inicio && forms.search.fecha_fin)" :loading="loading" xLarge/>
              </v-col>
            </v-row>
            <Calendar :events.sync="events" :dictionary="calendarValues" selectMode>
              <template v-slot:actions>
                <v-chip-group active-class="primary--text" class="ml-5" column>
                  <div v-for="(type, key, i) in frecuency" :key="i">
                    <v-chip :disabled="!avaibleChips" @click="createTable(key)"> {{ type }} </v-chip>
                  </div>
                </v-chip-group>
              </template>
            </Calendar>
          </v-card>
          <br />
        </div>
        <div v-if="e1 == 3" class="animate__animated animate__bounceInRight">
          <v-row no-gutters class="itemsCenter">
            <h2 class="subtitle">Seleccione tipo de incidencia y aplicar</h2>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" md="4">
              <Select-Option :data="types" :result.sync="resultOptions.types" title="Tipo de incidencia" :key="types.length"></Select-Option>
            </v-col>
            <v-col cols="12" md="4">
              <Select-Option :data="resultOptions.types.subtipos_solicitudes ? resultOptions.types.subtipos_solicitudes : []" :result.sync="resultOptions.subTypes" :title="resultOptions.types.nombre ? resultOptions.types.nombre : 'Subtipos de incidencias'" :key="resultOptions.types.length"></Select-Option>
            </v-col>
            <v-col cols="12" md="4">
              <Select-Option :data="resultOptions.subTypes ? actionsList : []" :result.sync="resultOptions.actions" title="Acciones" :key="actionsList.length"></Select-Option>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <br />
    <div class="text-right footer grey lighten-5">
      <v-divider></v-divider>
      <template>
        <div class="text-center scrollStyle">
          <v-dialog v-model="resume" width="600" persistent>
            <v-card>
              <v-card-title class="text-h5 primary white--text"> Paso 1 </v-card-title>
              <v-card-text>
                <v-virtual-scroll :bench="benched" :items="select" height="150" item-height="64">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item">
                      <v-list-item-action>
                        <v-btn fab x-small depressed color="primary">
                          {{ item.idusuario }}
                        </v-btn>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.nombre }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>
                  </template>
                </v-virtual-scroll>
              </v-card-text>
            </v-card>
            <br />
            <v-card>
              <v-card-title class="text-h5 primary white--text"> Paso 2 </v-card-title>

              <v-card-text>
                <v-timeline align-top dense>
                  <v-timeline-item v-for="(date, j) in forms.search" :key="j" color="primary" small>
                    <div>
                      <div class="font-weight-medium">
                        <strong>{{ j == "fecha_inicio" ? "Fecha de inicio" : "Fecha fin" }}: </strong>{{ date }}
                      </div>
                    </div>
                  </v-timeline-item>
                  <v-timeline-item color="primary" small>
                    <div>
                      <div class="font-weight-medium"><strong>Frecuencia: </strong>{{ frecuency[saveFrecuency] }}</div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
            <br />
            <v-card>
              <v-card-title class="text-h5 primary white--text"> Paso 3 </v-card-title>

              <v-card-text>
                <div v-for="(result, key, i) in resultOptions" :key="i">
                  <div v-if="result">
                    <v-list-item :key="i">
                      <v-list-item-action>
                        <v-btn fab x-small depressed color="primary"> </v-btn>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="font-weight-normal">
                          <strong>{{ resultOptionsKeys[key] }}: </strong>
                          {{ result ? result.nombre : "" }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <Basic-Btn text="Cerrar" color="primary"@click="$router.push('/planificador')" block/>
          </v-dialog>
        </div>
      </template>
      <Basic-Btn text="Atras" color="primary" @click="e1 > 1 ? (e1 = e1 - 1) : previousPage()" :loading="loading" typeButton="text" />
      <Basic-Btn text="Siguiente" color="primary" @click="e1 = e1 + 1" v-if="e1 < 3" :loading="loading" :disabled="validStep" />
      <Basic-Btn text="Finalizar" color="primary" @click="dialogs.remove = !dialogs.remove" v-if="e1 == 3" :loading="loading" :disabled="validStep" />
    </div>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea guardar la planificación?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="dialogs.remove = !dialogs.remove" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="estructureSendData()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </div>
</template>

<script>
import "animate.css";
import services from "@/utils/services";
import moment from "moment";
import { Loader, BasicBtn, Calendar, DatePicker, SelectChoice, SelectOption, DeleteMessage, BasicAlert, } from "@/components";

export default {
  name: "calendar",
  components: {
    Loader,
    BasicBtn,
    Calendar,
    DatePicker,
    SelectChoice,
    SelectOption,
    DeleteMessage,
    BasicAlert,
  },
  data: () => ({
    plannerMode: '',
    items: [],
    users: [],
    department: [],
    noSelect: [],
    select: [],
    types: [],
    subTypes: [],
    resultOptions: {
      types: {},
      subTypes: null,
      actions: null,
    },
    dialogs:{
      remove: false,
    },
    resultOptionsKeys: {
      types: "Tipo de evento/incidencia",
      subTypes: "Tipo de evento/incidencia",
      actions: "Accion",
    },
    resume: false,
    e1: 1,
    benched: 0,
    events: [],
    days: [],
    loading: false,
    avaibleChips: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    forms: {
      search: {
        fecha_inicio: "",
        fecha_fin: "",
      },
    },
    plannerData: {
      "accion": "",
      "departamentos": [],
      "usuarios": [],
      "fechas": [],
      "incidencia": ""
    },
    frecuency: {
      ninguno: "Ninguno",
      entre: "Entre semana",
      fines: "Fines de semana",
      sabado: "Sabado si, Sabado no",
      sabado2: "Dos sabados si, uno no",
      sabado7: "Siete sabados si, uno no",
    },
    saveFrecuency: "",
    steps: ["Paso 1: Seleccionar alcance de empleados", "Paso 2: Seleccione días consecutivos", "Paso 3: Seleccione tipo de evento/incidencia y aplicar"],
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
    calendarValues: [
      { key: "date", value: "Fecha" },
      { key: "outTime", value: "Tiempo de retardo(horas)" },
    ],
    actionsList: [
      { nombre: "Agregar", value: "agregar" },
      { nombre: "Eliminar", value: "eliminar" },
    ],
  }),
  mounted() {
    this.getGepartament();
    this.getUser();
    this.getTypes();
  },
  computed: {
    validStep() {
      let valid = true;
      switch (this.e1) {
        case 1:
          if (this.select.length > 0) {
            valid = false;
          }
          break;
        case 2:
          if (this.forms.search.fecha_inicio && this.forms.search.fecha_fin && this.events.length) {
            valid = false;
          }
          break;
        case 3:
          if (this.resultOptions.actions) {
            valid = false;
          }
          break;
      }
      return valid;
    },
  },
  methods: {
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombre =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTypes() {
      this.loading = true;
      this.axios
        .get(services.routes.requestType)
        .then((response) => {
          this.types = response.data.data;
          for (let i = 0; i < this.types.length; i++) {
            if (this.types[i].nombre === 'VACACIONES') {
              this.types.splice(i, 1);
              i--;
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
          this.getSubtypes();
        });
    },
    async getSubtypes() {
      this.loading = true;
      this.axios
        .get(services.routes.requestSubtype)
        .then((response) => {
          this.subTypes = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    changeSelect(var1) {
      this.plannerMode = var1
      this.noSelect = this[var1];
      this.select = [];
    },
    getGepartament() {
      this.loading = true;
      this.axios
        .get(services.routes.department)
        .then((response) => {
          this.department = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    createTable(type) {
      this.saveFrecuency = type;
      let begin = this.forms.search.fecha_inicio;
      let end = this.forms.search.fecha_fin;
      let total = moment(end).diff(moment(begin), "days") + 1;
      this.days = [];
      let sunday = false;
      let sundayCount = 0;
      for (let i = 0; i < total; i++) {
        if (type == "fines") {
          if (moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() == 6 || moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() == 0) {
            this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
          }
        } else if (type == "entre") {
          if (moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() != 6 && moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() != 0) {
            this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
          }
        } else if (moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() == 6 && type == "sabado") {
          sunday = !sunday;
          if (sunday) {
            this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
          }
        } else if (moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() == 6 && type == "sabado2") {
          sundayCount += 1;
          if (sundayCount < 3) {
            this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
          } else {
            sundayCount = 0
          }
        } else if (moment(moment(begin).add(i, "days").format("YYYY-MM-DD")).day() == 6 && type == "sabado7") {
          sundayCount += 1;
          if (sundayCount < 8) {
            this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
          } else {
            sundayCount = 0
          }
        }
      }

      this.updateRange();
    },
    updateRange() {
      const events = [];
      for (let i = 0; i < this.days.length; i++) {
        events.push({
          name: "Seleccionado",
          start: moment(this.days[i]).valueOf(),
          color: "primary",
          timed: true,
          outTime: this.secondsToString(this.days[i]),
          date: moment(this.days[i]).format("YYYY-MM-DD"),
        });
      }
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    secondsToString(seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      var second = seconds % 60;
      second = second < 10 ? "0" + second : second;
      return hour + ":" + minute + ":" + second;
    },
    estructureSendData() {
      if (this.select.length > 0) {
        this.plannerData.usuarios=[];
        this.plannerData.departamentos=[];
        let sendkey = 'usuarios'
        let key= 'idusuario'
        if(this.select[0].iddepartamento){
          sendkey = 'departamentos'
          key= 'iddepartamento'
        }
        for (let data of this.select) {
        this.plannerData[sendkey].push(data[key])          
        }
      }
      if (this.events.length > 0) {
        this.plannerData.fechas=[];
        for (let data of this.events) {
        this.plannerData.fechas.push(moment(data.date).format("YYYY-MM-DD"))          
        }
      }
      this.plannerData.accion = this.resultOptions.actions ? this.resultOptions.actions.value : '';
      this.plannerData.incidencia = this.resultOptions.subTypes ? this.resultOptions.subTypes.nombre : '';
      this.savePlanning();
    },
    savePlanning() {
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.plannerData));
      this.axios
        .put(services.routes.event + "/planning", { data })
        .then((response) => {
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Planificación realizada exitosamente";
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.resume = !this.resume
          this.loading = false;
        });
    },
    previousPage() {
      this.$router.push("/planificador");
    },
  },
};
</script>

<style scoped>
  .scrollStyle {
    scrollbar-color: #6c14146b #fcfcfc00 !important;
    scrollbar-base-color: rgba(234, 246, 248, 0.137);
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 500px 10px 10px #6c14146b;
  }
</style>
